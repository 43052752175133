'use client';

import { FieldProps, useField } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import Select, { OptionTypeBase, Props as SelectProps } from 'react-select';

import styles from './InboundV2FormikSelectInput.module.css';
import { If } from '@/components/UtilitiesComponents';

interface FieldPropsType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  name: string;
}

interface InboundV2FormikSelectInputProps extends SelectProps {
  label: string;
  name: string;
  error?: string;
  description?: string;
  onBlurCallBack?: (option: OptionTypeBase) => void;
}

const InboundV2FormikSelectInput: React.FC<
  InboundV2FormikSelectInputProps & FieldProps
> = ({
  label,
  description,
  placeholder = '',
  onBlurCallBack,
  ...props
}: FieldPropsType) => {
  const [field, meta, helpers] = useField(props);
  const [isSelectInputOpen, setIsSelectInputOpen] = useState(false);

  const { options } = props;
  const { touched, error, value } = meta;
  const { setValue } = helpers;

  const activeLabel = useMemo(() => value, [value]);

  const clearSelectValue = () => {
    const selectElement = document.getElementsByClassName(
      'react-select-form__single-value',
    )[0];
    if (selectElement) {
      selectElement.innerHTML = '';
    }
  };

  useEffect(() => {
    if (value === '') {
      clearSelectValue();
    }
  }, [value]);

  return (
    <div
      className={`${styles.selectContainer} ${
        isSelectInputOpen ? styles.selectContainerOpen : ''
      }`}
    >
      <label
        htmlFor={props.id || props.name}
        className={`${styles.label} ${
          isSelectInputOpen ? 'text-stone-500 z-30' : 'z-10'
        } ${activeLabel ? styles.labelActive : ''}`}
      >
        {label}
      </label>
      <Select
        id={field.name}
        options={options}
        aria-label={field.name}
        placeholder={placeholder}
        isSearchable={false}
        instanceId={props.iid}
        className={`${styles.select} ${
          !value ? styles.selectWithoutValue : ''
        } ${isSelectInputOpen ? 'z-20' : ''}`}
        classNamePrefix="react-select-form"
        onMenuOpen={() => setIsSelectInputOpen(true)}
        onMenuClose={() => setIsSelectInputOpen(false)}
        onChange={(option: OptionTypeBase) => {
          setValue(option.value);

          if (onBlurCallBack) onBlurCallBack(option);
        }}
        isDisabled={props.isDisabled}
      />
      <If condition={description}>
        <p className="mt-4 text-display-700 paragraph-14">{description}</p>
      </If>
      <If condition={Boolean(error && touched)}>
        <p className="mt-4 text-red-500 paragraph-14">{meta.error}</p>
      </If>
    </div>
  );
};

export default InboundV2FormikSelectInput;
